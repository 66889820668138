@import '../../global-defs';

.company-selector-form {
  font-size: 0.8rem;

  .company-selector {
    font-size: 0.8rem;
  }

  .onyo-button {
    font-size: 0.8rem;
    background-color: $onyo-orange;
  }
}
