@import '../../global-defs';

.login-form {

  font-size: 0.8rem;

  .form-input {
    font-size: 0.8rem;
  }

  .onyo-button {
    font-size: 0.8rem;
    background-color: $onyo-orange;
  }
}
