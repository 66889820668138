@import '../../global-defs';

.phone-input {
  .cellphone-input {
    background-color: #fff;
    text-align: center;
  }

  .send-button {
    background-color: $onyo-orange !important;
    border: 0px !important;
  }
}
