@import '../../global-defs.scss';

$background-z-index: 1;
$canvas-z-index: 20;
$content-z-index: 50;

.sgm {
  @include default-background;
  z-index: $background-z-index;
  min-height: 100vh;

  .sgm-content {
    z-index: $content-z-index;

    .config-button {
      position: absolute;
      color: $onyo-orange;
      opacity: 0.8;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
    }
  }

  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 97%;

    canvas {
      z-index: $canvas-z-index;
    }
  }

  .onyo-logo {
    height: 2rem;
    z-index: $content-z-index;
  }

  .sgm-text {
    color: $text-color;
    z-index: $content-z-index;
  }
}
