@import '../../global-defs';

.login-view {
  height: 100vh;

  .header-text {
    color: $onyo-orange;
    font-weight: bold;
  }

  .spinner-icon {
    color: $onyo-orange;
  }
}
