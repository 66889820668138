@import '../../global-defs';

.error-boundary {
  @include default-background;
  height: 100vh;

  .error-text {
    color: $text-color;
  }
}
