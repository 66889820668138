// Color scheme:
$onyo-orange: #f65a02;
$onyo-dark-orange: #d01e1e;
$text-color: #fff;

@mixin default-background {
  background: rgb(208,30,30);
  background: linear-gradient(90deg, rgba(208,30,30,1) 0%, rgba(246,90,2,1) 50%, rgba(208,30,30,1) 100%);
}

// Fonts
$font-family: 'Quicksand';
@mixin default-font-size {
  html {
    font-size: 1rem;
  }

  @include media-breakpoint-up(sm) {
    html {
      font-size: 1.2rem;
    }
  }

  @include media-breakpoint-up(md) {
    html {
      font-size: 1.4rem;
    }
  }

  @include media-breakpoint-up(lg) {
    html {
      font-size: 1.6rem;
    }
  }
}
