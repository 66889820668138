@import '../../global-defs';

.keyboard-key {

  .key-button {
    color: $text-color;
    font-weight: bold;
    border: solid 2px;
    border-color: $text-color;
    background-color: rgba($color: $onyo-orange, $alpha: 0);
    width: 4rem;

    &:active {
      background-color: $onyo-orange !important;
      border-color: $text-color !important;
    }

    &:active:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .5) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .key-icon {
    color: $text-color;
  }

}
