@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');
@import './global-defs';

@include default-font-size;

body {
  margin: 0;
  padding: 0;
  font-family: $font-family !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
