@import url(https://fonts.googleapis.com/css?family=Quicksand:400,700);
html {
  font-size: 1rem; }

@media (min-width: 576px) {
  html {
    font-size: 1.2rem; } }

@media (min-width: 768px) {
  html {
    font-size: 1.4rem; } }

@media (min-width: 992px) {
  html {
    font-size: 1.6rem; } }

body {
  margin: 0;
  padding: 0;
  font-family: "Quicksand" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.login-view {
  height: 100vh; }
  .login-view .header-text {
    color: #f65a02;
    font-weight: bold; }
  .login-view .spinner-icon {
    color: #f65a02; }

.company-selector-form {
  font-size: 0.8rem; }
  .company-selector-form .company-selector {
    font-size: 0.8rem; }
  .company-selector-form .onyo-button {
    font-size: 0.8rem;
    background-color: #f65a02; }

.login-form {
  font-size: 0.8rem; }
  .login-form .form-input {
    font-size: 0.8rem; }
  .login-form .onyo-button {
    font-size: 0.8rem;
    background-color: #f65a02; }


.error-boundary {
  background: #d01e1e;
  background: -webkit-linear-gradient(left, #d01e1e 0%, #f65a02 50%, #d01e1e 100%);
  background: linear-gradient(90deg, #d01e1e 0%, #f65a02 50%, #d01e1e 100%);
  height: 100vh; }
  .error-boundary .error-text {
    color: #fff; }

.sgm {
  background: #d01e1e;
  background: -webkit-linear-gradient(left, #d01e1e 0%, #f65a02 50%, #d01e1e 100%);
  background: linear-gradient(90deg, #d01e1e 0%, #f65a02 50%, #d01e1e 100%);
  z-index: 1;
  min-height: 100vh; }
  .sgm .sgm-content {
    z-index: 50; }
    .sgm .sgm-content .config-button {
      position: absolute;
      color: #f65a02;
      opacity: 0.8;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer; }
  .sgm .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 97%; }
    .sgm .particles canvas {
      z-index: 20; }
  .sgm .onyo-logo {
    height: 2rem;
    z-index: 50; }
  .sgm .sgm-text {
    color: #fff;
    z-index: 50; }

.keyboard-key .key-button {
  color: #fff;
  font-weight: bold;
  border: solid 2px;
  border-color: #fff;
  background-color: rgba(246, 90, 2, 0);
  width: 4rem; }
  .keyboard-key .key-button:active {
    background-color: #f65a02 !important;
    border-color: #fff !important; }
  .keyboard-key .key-button:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
  .keyboard-key .key-button:focus {
    box-shadow: none; }

.keyboard-key .key-icon {
  color: #fff; }

.phone-input .cellphone-input {
  background-color: #fff;
  text-align: center; }

.phone-input .send-button {
  background-color: #f65a02 !important;
  border: 0px !important; }

